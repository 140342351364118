import moment from "moment";

export const modelAiOptions = [
  {
    value: "gemini-2.0-flash-exp",
    label: "gemini-2.0-flash-exp",
  },
  {
    value: "gemini-1.5-flash",
    label: "gemini-1.5-flash",
  },
  {
    value: "gemini-1.5-flash-8b",
    label: "gemini-1.5-flash-8b",
  },
  {
    value: "gemini-1.5-pro",
    label: "gemini-1.5-pro",
  },
  {
    value: "text-embedding-004",
    label: "text-embedding-004",
  },
  {
    value: "aqa",
    label: "aqa",
  },
];

export enum instructionType {
  CheckIn,
  OneToOne,
  Conference,
  CheckInAndOneToOne,
}

export const instructionOptions = [
  {
    value: instructionType.CheckIn,
    label: "Check In",
  },
  {
    value: instructionType.OneToOne,
    label: "One to One",
  }
];

export enum MessageRole {
  System,
  User,
  Model,
}

export enum FILTER_NAME {
  Grade = "Grade",
  StartDate = "Start Date",
  EndDate = "End Date",
  Calendar = "Calendar",
}

export const CALENDAR_OPTIONS = [
  {
    label: "Today",
    startDate: moment().startOf("day").valueOf(),
    endDate: moment().endOf("day").valueOf(),
    value: "Today",
  },
  {
    label: "This week",
    startDate: moment().startOf("week").weekday(1).valueOf(),
    endDate: moment().endOf("week").weekday(7).valueOf(),
    value: "Week",
  },
  {
    label: "This month",
    startDate: moment().startOf("month").valueOf(),
    endDate: moment().endOf("month").valueOf(),
    value: "Month",
  },
];

export const GRADE_DEFAULT: string[] = [
  "All",
  "K",
  "G",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const DEFAULT_START_DATE = moment().subtract(1, 'months').startOf("day").valueOf()
export const DEFAULT_END_DATE = moment().endOf("day").valueOf()

export const sampleDataSession = [
  {
    studentName: "Kriness",
    grade: 10,
    question: "Stress",
    answer: "20%"
  },
  {
    studentName: "Yellsin",
    grade: 10,
    question: "Safety",
    answer: "80%"
  },
  {
    studentName: "Nana",
    grade: 10,
    question: "well- being",
    answer: "20%"
  },
  {
    studentName: "Saru",
    grade: 10,
    question: "Are you ready to learn?",
    answer: "Yes"
  },
]