import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setLoading, setAlert, setModal } from "../../../redux/commons/action";

import {
  IAnswer,
  IHistoryPrompt,
  IOption,
  IPrompt,
  IPromptParams,
} from "../ultils/type";
import {
  createHistory,
  get,
  getAllSchool,
  getHistory,
  getSessionAnswer,
  removeHistory,
} from "../../../services/promptService";
import { School } from "../../../types/School";
import {
  GenerateContentStreamResult,
  GoogleGenerativeAI,
} from "@google/generative-ai";
import {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
  instructionType,
  MessageRole,
} from "../ultils/constant";
import { Filter } from "../../../types/Filter";
import { DEFAULT_FILTER } from "../../../utils/constants";

const useConversationPrompt = () => {
  //   const history = useHistory();
  const dispatch = useDispatch();
  //   const [filter, setFilter] = useState<Filter>(DEFAULT_FILTER);
  const [prompts, setPrompts] = useState<IPrompt[]>([]);
  const [promptOptions, setPromptOptions] = useState<IOption[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<IOption[]>([]);
  const [selectedPrompt, setSelectedPrompt] = useState<IPrompt | null>(null);
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const [params, setParams] = useState<IPromptParams>({
    schoolId: 0,
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
  });
  const [response, setResponse] = useState<string>("");
  const [filter, setFilter] = useState<Filter>(DEFAULT_FILTER);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [historyPrompts, setHistoryPrompts] = useState<IHistoryPrompt[]>([]);

  const changeFilter = (valueObject: any) =>
    setFilter({
      ...filter,
      ...valueObject,
    });

  const handleChangeParams = (value: IPromptParams) => {
    setParams({ ...params, ...value });
  };

  const onChangeParams = (name: string, value: number | string) => {
    setParams({ ...params, [name]: value });
  };

  const onSelectPrompt = (id: number) => {
    const prompt = prompts.find((item) => item.id === id) || null;
    setSelectedPrompt(prompt);
    handleChangeParams({
      question: prompt?.question,
      instruction: prompt?.instruction,
    });
  };

  const getDataPrompt = useCallback(async () => {
    dispatch(setLoading(true));

    try {
      const res = await get({ page: 1, limit: 1000, search: "" });
      const { items } = res.data;
      setPrompts(items || []);
      setPromptOptions(
        items?.map((item: IPrompt) => ({ value: item.id, label: item.name })) ||
          []
      );
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }

    dispatch(setLoading(false));
  }, []);

  const getDataSchool = useCallback(async () => {
    dispatch(setLoading(true));

    try {
      const res = await getAllSchool({ page: 1, limit: 1000, search: "" });
      const { items } = res.data;
      setSchoolOptions(
        items?.map((item: School) => ({ value: item.id, label: item.name })) ||
          []
      );
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }

    dispatch(setLoading(false));
  }, []);

  const getSessionAnswerSchool = useCallback(async () => {
    dispatch(setLoading(true));

    try {
      const res = await getSessionAnswer(params);
      const answers: IAnswer[] = res.data || [];
      if (answers.length) {
        setAnswers(answers);
        askGeminiAI(answers);
      }
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }

    dispatch(setLoading(false));
  }, [params]);

  const saveHistoryPrompt = useCallback(async (data: IHistoryPrompt) => {
    try {
      await createHistory(data);
      getDataHistory()
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }
  }, []);

  const askGeminiAI = async (answers: IAnswer[]) => {
    try {
      if (!selectedPrompt) throw new Error("Please select a prompt");
      setResponse("");
      const genAI = new GoogleGenerativeAI(
        "AIzaSyAb1MebW5BcT__I8WZtbvKkDHYzZDHKZro"
      );

      const model = genAI.getGenerativeModel({
        model: selectedPrompt.aiModel,
      });

      const dataInputPrompt = `Here is the input data: ${answers.map(
        (r, index) =>
          `Answer ${index}: student name: ${r.fullName}, answer: ${r.answer}, student grade: ${r.grade}; `
      )}`;

      const resultStream: GenerateContentStreamResult =
        await model.generateContentStream({
          contents: [
            {
              role: "user",
              parts: [
                {
                  text: dataInputPrompt,
                },
              ],
            },
          ].concat(
            selectedPrompt.messages
              .filter((r) => r.role != MessageRole.System)
              .map((i) => ({
                role: MessageRole[i.role]?.toLocaleLowerCase(),
                parts: [
                  {
                    text: i.message,
                  },
                ],
              }))
          ),
          systemInstruction: {
            role: "system",
            parts: selectedPrompt.messages
              .filter((r) => r.role == MessageRole.System)
              .map((i) => ({
                text: i.message,
              })),
          },
          generationConfig: {
            temperature: selectedPrompt.temperature,
            topP: selectedPrompt.topP,
            topK: selectedPrompt.topK,
            maxOutputTokens: selectedPrompt.maxOutputTokens,
            frequencyPenalty: selectedPrompt.frequencyPenalty,
            presencePenalty: selectedPrompt.presencePenalty,
            responseMimeType: "text/plain",
          },
        });

      let aiResponse = "";
      for await (const chunk of resultStream.stream) {
        const partialText = chunk.text();
        aiResponse += partialText;

        setResponse((prev) => prev + partialText);
      }

      saveHistoryPrompt({
        ...selectedPrompt,
        messages: JSON.stringify(selectedPrompt.messages),
        output: aiResponse,
        startTime: params.startDate,
        endTime: params.endDate,
        promptId: selectedPrompt.id,
        grade: params.grade || "",
        calendar: params.calendar || "",
      });
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      );
    }
  };

  const getDataHistory = useCallback(async () => {
    dispatch(setLoading(true));

    try {
      const res = await getHistory(filter);
      const { totalItems, items } = res.data;
      setHistoryPrompts(items || []);
      setTotalItems(totalItems || 0);
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }

    dispatch(setLoading(false));
  }, [filter]);

  const removeData = useCallback(
    async (id: number) => {
      dispatch(
        setModal({
          isOpen: true,
          type: "warning",
          message: "Do you want to delete this history?",
          onConfirm: async () => {
            dispatch(setLoading(true));

            try {
              await removeHistory(id);
              changeFilter({ page: 1 });
              dispatch(
                setAlert({
                  type: "success",
                  message: "Delete history successfully",
                })
              );
            } catch (err) {
              dispatch(
                setAlert({
                  type: "danger",
                  message: err.response?.data || err.message,
                })
              );
            }

            dispatch(setLoading(false));
          },
        })
      );
    },
    []
  );

  useEffect(() => {
    getDataPrompt();
    getDataSchool();
  }, []);

  useEffect(() => {
    getDataHistory();
  }, [filter]);

  return {
    prompts,
    promptOptions,
    setSelectedPrompt,
    selectedPrompt,
    schoolOptions,
    onSelectPrompt,
    params,
    onChangeParams,
    askGeminiAI,
    response,
    handleChangeParams,
    getSessionAnswerSchool,
    answers,
    totalItems,
    historyPrompts,
    removeData,
    filter
  };
};

export default useConversationPrompt;
