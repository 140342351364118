import React from "react";
import Markdown from "react-markdown";
import styles from "../styles/styles.module.scss";
import useHistoryPrompt from "../hooks/useHistory";
import { Table } from "reactstrap";
import { instructionType } from "../ultils/constant";

const HistoryDetail = () => {
  const { historyDetail } = useHistoryPrompt();
  return (
    <div>
      <h4>Question detail</h4>
      <Table responsive bordered striped hover size="sm" className="my-3">
        <thead>
          <tr>
            <th>Name prompt</th>
            <th>Temperature</th>
            <th>Max Tokens</th>
            <th>Presence Penalty</th>
            <th>Frequency Penalty</th>
            <th>Top p</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{historyDetail?.name}</td>

            <td>{historyDetail?.temperature}</td>
            <td>{historyDetail?.maxOutputTokens}</td>
            <td>{historyDetail?.presencePenalty}</td>
            <td>{historyDetail?.frequencyPenalty}</td>
            <td>{historyDetail?.topP}</td>
          </tr>
        </tbody>
      </Table>

      <Table responsive bordered striped hover size="sm" className="my-3 w-100" style={{maxWidth: 800}}>
        <thead>
          <tr>
            <th>Start time</th>
            <th>End time</th>
            <th>Data</th>
            <th>Grade</th>
            <th>Question</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {new Date(
                historyDetail?.startDate ?? "1970-01-01T00:00:00Z"
              ).toLocaleDateString("vi-VN")}
            </td>
            <td>
              {new Date(
                historyDetail?.endDate ?? "1970-01-01T00:00:00Z"
              ).toLocaleDateString("vi-VN")}
            </td>
            <td>
              {instructionType[historyDetail?.instruction ?? 0].toString()}
            </td>
            <td>{historyDetail?.grade}</td>
            <td>{historyDetail?.question}</td>
          </tr>
        </tbody>
      </Table>

      <h4>Ai output</h4>
      <div className={`border rounded p-3 ${styles["ai-output"]}`}>
        <Markdown>{historyDetail?.output || ""}</Markdown>
      </div>
    </div>
  );
};

export default HistoryDetail;
