import * as yup from "yup"

const schema = yup.object({
  name: yup.string().required("Prompt name is required"),
  description: yup.string().required("Description is required"),
  temperature: yup.number().required("Temperature is required"),
  maxOutputTokens: yup.number().required("Maximum output token is required"),
  aiModel: yup.string().required("Model is required"),
  // sampleData: yup.string().required("Sample data is required"),
})

export default schema
