import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { IMessage, IPrompt } from "../ultils/type";
import { setAlert, setLoading } from "../../../redux/commons/action";
import { create, getById, update } from "../../../services/promptService";
import { useHistory, useParams } from "react-router-dom";
import { instructionType, MessageRole } from "../ultils/constant";

const usePromptDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const textareasRef = useRef<HTMLTextAreaElement[]>([]);
  const lastInputRef = useRef<HTMLTextAreaElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [promptDetail, setPromptDetail] = useState<IPrompt>({
    description: "",
    name: "",
    temperature: 1,
    maxOutputTokens: 8192,
    stopSequences: "",
    topP: 0.95,
    topK: 40,
    frequencyPenalty: 0,
    presencePenalty: 0,
    aiModel: "gemini-1.5-flash",
    messages: [],
    id: 0,
    instruction: instructionType.CheckIn,
    sampleData: "",
    question: ""
  });

  const toggle = () => setIsOpen(!isOpen);

  const backToPromptList = () => {
    history.push("/gemini-prompt");
  };

  const toggleRole = (currentRole: MessageRole) => {
    switch (currentRole) {
      case MessageRole.System:
        return MessageRole.User;
      case MessageRole.User:
        return MessageRole.Model;
      case MessageRole.Model:
      default:
        return MessageRole.System;
    }
  };

  const handleDeleteMessage = (
    index: number,
    setFieldValue: any,
    values: any
  ) => {
    const updatedMessages = values.messages.filter(
      (_: IMessage, i: number) => i !== index
    );
    setFieldValue("messages", updatedMessages);
  };

  const handleCreatePrompt = async (values: IPrompt) => {
    dispatch(setLoading(true));
    try {
      const res = await create(values);
      if (res.data) {
        dispatch(
          setAlert({
            type: "success",
            message: "Create prompt successfully",
          })
        );

        backToPromptList();
      }
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }
    dispatch(setLoading(false));
  };

  const getPromptById = async (id: string) => {
    dispatch(setLoading(true));
    try {
      const res = await getById(parseInt(id));
      if (res.data) {
        setPromptDetail(res.data);
      }
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }
    dispatch(setLoading(false));
  };

  const updatePrompt = async (values: IPrompt) => {
    dispatch(setLoading(true));
    try {
      const res = await update(values);
      if (res.data) {
        dispatch(
          setAlert({
            type: "success",
            message: "Update prompt successfully",
          })
        );

        backToPromptList();
      }
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    setTimeout(() => {
      textareasRef.current.forEach((textarea) => {
        if (textarea) {
          textarea.style.height = "auto";
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
      });
    }, 0);
  }, [promptDetail.messages]);

  useEffect(() => {
    if (id) {
      getPromptById(id);
    }
  }, [id]);

  return {
    promptDetail,
    lastInputRef,
    handleDeleteMessage,
    setPromptDetail,
    handleCreatePrompt,
    backToPromptList,
    id,
    updatePrompt,
    toggleRole,
    textareasRef,
    isOpen,
    toggle
  };
};

export default usePromptDetail;
