import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Filter } from "../../../types/Filter";
import { DEFAULT_FILTER } from "../../../utils/constants";
import { setLoading, setAlert, setModal } from "../../../redux/commons/action";

import { IPrompt } from "../ultils/type";
import { get, remove } from "../../../services/promptService";

const PROMPT_DETAIL_URL = "/gemini-prompt";
const CREATE_PROMPT_URL = "/gemini-prompt/create-new";
const usePromptList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<Filter>(DEFAULT_FILTER);
  const [prompts, setPrompts] = useState<IPrompt[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  const changeFilter = (valueObject: any) =>
    setFilter({
      ...filter,
      ...valueObject,
    });

  const getData = useCallback(async () => {
    dispatch(setLoading(true));

    try {
      const res = await get(filter);
      const { totalItems, items } = res.data
      setPrompts(items || []);
      setTotalItems(totalItems || 0);
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }

    dispatch(setLoading(false));
  }, [filter]);

  const removeData = useCallback(
    async (id: number) => {
      dispatch(
        setModal({
          isOpen: true,
          type: "warning",
          message: "Do you want to delete this prompt?",
          onConfirm: async () => {
            dispatch(setLoading(true));

            try {
              await remove(id);
              changeFilter({ page: 1 });
              dispatch(
                setAlert({
                  type: "success",
                  message: "Delete prompt successfully",
                })
              );
            } catch (err) {
              dispatch(
                setAlert({
                  type: "danger",
                  message: err.response?.data || err.message,
                })
              );
            }

            dispatch(setLoading(false));
          },
        })
      );
    },
    [getData]
  );

  const goToDetail = useCallback((id?: number) => {
    const url = id ? `${PROMPT_DETAIL_URL}/${id}` : CREATE_PROMPT_URL;
    history.push(url);
  }, []);

  useEffect(() => {
    getData();
  }, [filter]);

  return {
    PROMPT_DETAIL_URL,
    prompts,
    setPrompts,
    totalItems,
    filter,
    removeData,
    changeFilter,
    goToDetail,
  };
};

export default usePromptList;
