import React from "react";
import CommonSelector from "../../../components/Selectors/CommonSelector";
import useConversationPrompt from "../hooks/useConversationPrompt";
import { Input, Table } from "reactstrap";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import Markdown from "react-markdown";
import styles from "../styles/styles.module.scss";
import {
  FILTER_NAME,
  instructionOptions,
  instructionType,
} from "../ultils/constant";
import AddButton from "../../../components/Buttons/AddButton";
import SelectFilter from "../components/SelectFilter";
import { Link } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon";

const ConversationPrompt = () => {
  const {
    promptOptions,
    onSelectPrompt,
    selectedPrompt,
    schoolOptions,
    params,
    onChangeParams,
    askGeminiAI,
    response,
    handleChangeParams,
    getSessionAnswerSchool,
    answers,
    historyPrompts,
    removeData,
    filter,
    totalItems,
  } = useConversationPrompt();
  const changeFilter = (filter: any) => {};

  return (
    <div>
      <CommonSelector
        className="w-50"
        placeholder="Select Prompt"
        defaultValue={selectedPrompt?.id}
        options={promptOptions || []}
        onChange={(e: any) => {
          onSelectPrompt(e.value ?? 0);
        }}
      />
      <CommonSelector
        className="my-3"
        placeholder="Select School"
        defaultValue={params.schoolId}
        options={schoolOptions || []}
        onChange={(e: any) => {
          onChangeParams("schoolId", e.value ?? 0);
        }}
      />

      <div className="d-flex align-items-center w-100 my-3">
        <SelectFilter
          name={FILTER_NAME.Calendar}
          filter={params}
          onChangeFilters={handleChangeParams}
        />
        <SelectFilter
          name={FILTER_NAME.StartDate}
          filter={params}
          onChangeFilters={handleChangeParams}
        />
        <SelectFilter
          name={FILTER_NAME.EndDate}
          filter={params}
          onChangeFilters={handleChangeParams}
        />
        <SelectFilter
          name={FILTER_NAME.Grade}
          filter={params}
          onChangeFilters={handleChangeParams}
        />
      </div>

      <div className="d-flex align-items-center justify-content-between w-100 my-3">
        <div className="d-flex align-items-center">
          <h5 className="mr-3">Question</h5>
          <Input
            // className="w-25"
            type="text"
            name={"question"}
            value={params.question}
            onChange={(e) => handleChangeParams({ question: e.target.value })}
          />
        </div>
        <AddButton text="Generate" onClick={() => getSessionAnswerSchool()} />
      </div>

      <div className={`border rounded p-3 w-100 ${styles["data-block"]}`}>
        <div className="d-flex justify-content-between align-items-center mb-5">
          <div className="d-flex align-items-center w-50">
            <h4 className="mr-4">Real data</h4>
            <CommonSelector
              defaultValue={params?.instruction}
              options={instructionOptions}
              placeholder="Select instruction"
              className="w-50"
              onChange={(e: any) => {
                onChangeParams("instruction", e.value);
              }}
            />
          </div>
        </div>

        <Table
          responsive
          bordered
          striped
          hover
          size="sm"
          className={`mb-3 ${styles["table-answer"]}`}
        >
          <thead>
            <tr>
              <th>Student Name</th>
              <th>Grade</th>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            {answers?.map((answer, index) => (
              <tr key={index}>
                <td>{answer.fullName}</td>
                <td>{answer.grade}</td>
                <td>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: answer.question.replace(/<br\s*\/?>/gi, "\n"),
                    }}
                  />
                </td>
                <td>{answer.answer}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <h4 className="mt-5">Ai output</h4>
      <div className={`border rounded p-3 ${styles["ai-output"]}`}>
        <Markdown>{response}</Markdown>
      </div>

      <h4 className="mt-5">Prompt History</h4>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th>Prompts</th>
            <th>Start time</th>
            <th>End time</th>
            <th>Data type</th>
            <th>Params</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {historyPrompts?.map((history, index) => (
            <tr key={history?.id}>
              <td>{history.name}</td>
              <td>
                {new Date(
                  history?.startDate ?? "1970-01-01T00:00:00Z"
                ).toLocaleDateString("vi-VN")}
              </td>
              <td>
                {new Date(
                  history?.endDate ?? "1970-01-01T00:00:00Z"
                ).toLocaleDateString("vi-VN")}
              </td>
              <td>{instructionType[history.instruction].toString()}</td>
              <td className="d-flex flex-column">
                <span>{`Grade: ${history.grade}`}</span>
                <span>{`Question: ${history.question}`}</span>
              </td>
              <td className="text-center">
                <DeleteButtonIcon
                  onClick={() => removeData(history.id as number)}
                />

                <Link to={`/history-prompt/${history.id}`}>
                  <FaRegEye
                    className="ml-3 cursor-pointer hover-opacity"
                    style={{ fontSize: 18 }}
                  />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-right">
        <CustomPagination
          page={filter.page}
          limit={filter.limit}
          totalItems={totalItems}
          changePage={(page: number) => changeFilter({ page })}
          changeLimit={(limit: number) => changeFilter({ limit })}
        />
      </div>
    </div>
  );
};

export default ConversationPrompt;
