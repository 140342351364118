import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import styles from "../styles/styles.module.scss";
import { CALENDAR, PROPS_FILTERS_COMPONENT } from "../ultils/type";
import {
  CALENDAR_OPTIONS,
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
  FILTER_NAME,
  GRADE_DEFAULT,
} from "../ultils/constant";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Form, FormGroup, Input, Label } from "reactstrap";

const SelectFilter = ({
  name,
  onChangeFilters,
  filter,
}: PROPS_FILTERS_COMPONENT) => {
  const ref = useRef<any>(null);
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);
  const handleToggleCollapse = () => {
    setIsOpenCollapse(!isOpenCollapse);
  };

  const handleChangeStartDate = (date: any) => {
    !!onChangeFilters &&
      onChangeFilters({
        startDate: moment(date).startOf("day").valueOf(),
        calendar: "",
      });
    setIsOpenCollapse(false);
  };

  const handleChangeEndDate = (date: any) => {
    !!onChangeFilters &&
      onChangeFilters({
        endDate: moment(date).endOf("day").valueOf(),
        calendar: "",
      });
    setIsOpenCollapse(false);
  };

  const handleClickOutSide = (event: MouseEvent) => {
    if (ref.current && !ref?.current?.contains?.(event.target))
      setIsOpenCollapse(false);
  };

  const onChangeGrade = (grade: string) => {
    if (!grade?.trim()) {
      !!onChangeFilters && onChangeFilters({ grade: "" });
    } else {
      !!onChangeFilters && onChangeFilters({ grade: grade });
    }
  };

  const onChangeCalendar = (calendar: CALENDAR) => {
    if (!calendar) {
      !!onChangeFilters &&
        onChangeFilters({
          startDate: DEFAULT_START_DATE,
          endDate: DEFAULT_END_DATE,
          calendar: "",
        });
    } else {
      !!onChangeFilters &&
        onChangeFilters({
          startDate: calendar.startDate,
          endDate: calendar.endDate,
          calendar: calendar.label,
        });
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
  }, []);

  const renderSelectFilter = () => {
    if (name === FILTER_NAME.StartDate) {
      return (
        <div
          key={`${name}`}
          className={`${styles["block-filter-component"]}`}
          ref={ref}
        >
          <button
            onClick={handleToggleCollapse}
            className={`${styles["btn-filter"]} d-flex align-items-center cursor-pointer`}
          >
            <p className={`${styles["color-filter"]} my-0 mx-2`}>
              {name}
              {!!filter?.startDate &&
                `: ${moment(filter?.startDate).format("DD/MM/YYYY")}`}
            </p>
            {!isOpenCollapse ? <FiChevronDown /> : <FiChevronUp />}
          </button>
          {isOpenCollapse && (
            <div className={`${styles["date-picker"]} date-picker-admin`}>
              <DatePicker
                selected={moment(filter?.startDate).toDate()}
                onChange={(date: any) => handleChangeStartDate(date)}
                inline
              />
            </div>
          )}
        </div>
      );
    }

    if (name === FILTER_NAME.EndDate) {
      return (
        <div
          key={`${name}`}
          className={`${styles["block-filter-component"]}`}
          ref={ref}
        >
          <button
            onClick={handleToggleCollapse}
            className={`${styles["btn-filter"]} d-flex align-items-center cursor-pointer`}
          >
            <p className={`${styles["color-filter"]} my-0 mx-2`}>
              {name}
              {!!filter?.endDate &&
                `: ${moment(filter?.endDate).format("DD/MM/YYYY")}`}
            </p>
            {!isOpenCollapse ? <FiChevronDown /> : <FiChevronUp />}
          </button>
          {isOpenCollapse && (
            <div className={`${styles["date-picker"]} date-picker-admin`}>
              <DatePicker
                selected={moment(filter?.endDate).toDate()}
                onChange={(date: any) => handleChangeEndDate(date)}
                inline
              />
            </div>
          )}
        </div>
      );
    }

    if (name == FILTER_NAME.Calendar) {
      return (
        <div
          key={`${name}`}
          className={`${styles["block-filter-component"]} `}
          ref={ref}
        >
          <button
            onClick={handleToggleCollapse}
            className={`${styles["btn-filter"]} d-flex align-items-center cursor-pointer`}
          >
            <p className={`${styles["color-filter"]} my-0 mx-2`}>
              {name}
              {!!filter?.calendar && `: ${filter?.calendar}`}
            </p>
            {!isOpenCollapse ? <FiChevronDown /> : <FiChevronUp />}
          </button>
          <div
            className={`${styles["select-filter-component"]} ${
              !isOpenCollapse && `${styles["hidden"]}`
            }`}
          >
            <Form
              className={`${styles["box-select"]} ${styles["box-select-format"]}`}
            >
              {CALENDAR_OPTIONS?.map((e) => (
                <FormGroup check className="my-2 px-3" key={`${e.label}`}>
                  <Input
                    id={e.label}
                    name="calendar"
                    value={[e.label]}
                    type="radio"
                    className={`${styles["radio-btn"]}`}
                    checked={filter?.calendar === e.label}
                    onChange={() => onChangeCalendar(e)}
                  />
                  <Label
                    check
                    for={e.label}
                    className={`${styles["box-select-label"]}`}
                  >
                    <p>{e.label}</p>
                  </Label>
                </FormGroup>
              ))}
            </Form>
          </div>
        </div>
      );
    }

    if (name == FILTER_NAME.Grade) {
      return (
        <div
          key={`${name}`}
          className={`${styles["block-filter-component"]} `}
          ref={ref}
        >
          <button
            onClick={handleToggleCollapse}
            className={`${styles["btn-filter"]} d-flex align-items-center cursor-pointer`}
          >
            <p className={`${styles["color-filter"]} my-0 mx-2`}>
              {name}
              {!!filter?.grade && `: ${filter?.grade}`}
            </p>
            {!isOpenCollapse ? <FiChevronDown /> : <FiChevronUp />}
          </button>
          <div
            className={`${styles["select-filter-component"]} ${
              !isOpenCollapse && `${styles["hidden"]}`
            }`}
          >
            <Form
              className={`${styles["box-select"]} ${styles["box-select-format"]}`}
            >
              {GRADE_DEFAULT?.map((e) => (
                <FormGroup check className="my-2 px-3" key={`${e}`}>
                  <Input
                    id={e + "grade"}
                    name="grade"
                    value={filter?.grade}
                    type="radio"
                    className={`${styles["radio-btn"]}`}
                    checked={filter?.grade === e}
                    onChange={() => onChangeGrade(e)}
                  />
                  <Label
                    check
                    for={e + "grade"}
                    className={`${styles["box-select-label"]}`}
                  >
                    <p>{e}</p>
                  </Label>
                </FormGroup>
              ))}
            </Form>
          </div>
        </div>
      );
    }

    return <div />;
  };

  return <React.Fragment>{renderSelectFilter()}</React.Fragment>;
};

export default SelectFilter;
