import { Editor } from "@tinymce/tinymce-react";
import React, { Fragment } from "react";
import { Collapse, Input, Table } from "reactstrap";
import { TINY_MCE_KEY } from "../../../utils/constants";
import { editorConfig } from "../../LegalPages/constants/constant";
import CommonSelector from "../../../components/Selectors/CommonSelector";
import styles from "../styles/styles.module.scss";
import DetailActionButtons from "../../../components/Buttons/DetailActionButtons";
import usePromptDetail from "../hooks/usePromptDetail";
import { Formik } from "formik";
import schema from "../ultils/schema";
import ErrorHandler from "../../../components/Alerts/ErrorHandler";
import {
  instructionOptions,
  MessageRole,
  modelAiOptions,
  sampleDataSession,
} from "../ultils/constant";
import { IMessage, IPrompt } from "../ultils/type";
import { IoIosArrowDown } from "react-icons/io";

const PromptDetail = () => {
  const {
    promptDetail,
    lastInputRef,
    handleDeleteMessage,
    handleCreatePrompt,
    backToPromptList,
    id,
    updatePrompt,
    toggleRole,
    textareasRef,
    isOpen,
    toggle,
  } = usePromptDetail();

  return (
    <div className="animated fadeIn pb-3">
      <Formik
        initialValues={promptDetail}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values: IPrompt) => {
          !values.id ? handleCreatePrompt(values) : updatePrompt(values);
        }}
      >
        {(formikProps) => {
          const {
            values,
            touched,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
          } = formikProps;

          const handleAddMessage = () => {
            const newMessage = {
              role: MessageRole.System,
              message: "",
            };

            setFieldValue("messages", [...values.messages, newMessage]);
            setTimeout(() => {
              lastInputRef.current?.focus();
            }, 0);
          };

          return (
            <Fragment>
              <h5>Name prompt</h5>
              <Input
                type="text"
                name="name"
                value={values.name}
                className="w-50"
                onChange={handleChange}
              />
              {touched.name && errors.name && (
                <ErrorHandler text={errors.name as string} />
              )}

              <h5 className="mt-3">Description</h5>
              <Editor
                apiKey={TINY_MCE_KEY}
                value={values.description}
                init={{
                  ...editorConfig,
                  height: 194,
                  value: values.description,
                  paste_preprocess: function (_plugin: any, args: any) {
                    args.content = args.content.replace(
                      /<\/?(?!b\b|p\b)[^>]+>/g,
                      ""
                    );
                  },
                }}
                onEditorChange={(content) => {
                  setFieldValue("description", content);
                }}
              />

              {touched.description && errors.description && (
                <ErrorHandler text={errors.description as string} />
              )}

              <div className="my-3 w-75 border rounded p-3">
                <h4>Generation Config</h4>
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    className={`d-flex align-items-center ${styles["config-name"]}`}
                  >
                    <img
                      src="/images/question.svg"
                      data-toggle="tooltip"
                      title="Model gemini ai"
                      className="mr-2"
                    />
                    <p className="m-0">Version</p>
                  </div>
                  <CommonSelector
                    className="w-100 pl-3"
                    defaultValue={values.aiModel}
                    options={modelAiOptions}
                    onChange={(e: any) => {
                      setFieldValue("aiModel", e.value ?? "");
                      if (e.value == "aqa" || values.aiModel == "aqa") {
                        setFieldValue(
                          "maxOutputTokens",
                          e.value != "aqa" ? 8192 : 1024
                        );
                      }
                    }}
                  />
                </div>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div
                    className={`d-flex align-items-center ${styles["config-name"]}`}
                  >
                    <img
                      src="/images/question.svg"
                      data-toggle="tooltip"
                      title="Controls the randomness of the output"
                      className="mr-2"
                    />
                    <p className="m-0">Temperature</p>
                  </div>

                  <div className="d-flex align-items-center w-100 pl-3">
                    <input
                      type="range"
                      min="0.0"
                      max="2.0"
                      step="0.1"
                      name="temperature"
                      value={values.temperature}
                      onChange={handleChange}
                      className={` ${styles["slider"]} w-100`}
                      data-orientation="vertical"
                    />
                    <p className="mb-0 ml-2">{values.temperature}</p>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div
                    className={`d-flex align-items-center ${styles["config-name"]}`}
                  >
                    <img
                      src="/images/question.svg"
                      data-toggle="tooltip"
                      title="The maximum number of tokens to include in a response candidate"
                      className="mr-2"
                    />
                    <p className="m-0">Output length</p>
                  </div>

                  <div className="d-flex align-items-center w-100 pl-3">
                    <input
                      type="range"
                      min="0"
                      max={values.aiModel != "aqa" ? 8192 : 1024}
                      name="maxOutputTokens"
                      value={values.maxOutputTokens}
                      onChange={handleChange}
                      className={` ${styles["slider"]} w-100`}
                      data-orientation="vertical"
                    />
                    <p className="mb-0 ml-2">{values.maxOutputTokens}</p>
                  </div>
                </div>

                <Collapse isOpen={isOpen}>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div
                      className={`d-flex align-items-center ${styles["config-name"]}`}
                    >
                      <img
                        src="/images/question.svg"
                        data-toggle="tooltip"
                        title="The set of character sequences (up to 5) that will stop output generation. If specified, the API will stop at the first appearance of a stop_sequence. The stop sequence will not be included as part of the response"
                        className="mr-2"
                      />
                      <p className="m-0">Stop sequences</p>
                    </div>

                    <div className="d-flex align-items-center w-100 pl-3">
                      <Input
                        type="text"
                        name={"stopSequences"}
                        className="w-100"
                        value={values.stopSequences}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div
                      className={`d-flex align-items-center ${styles["config-name"]}`}
                    >
                      <img
                        src="/images/question.svg"
                        data-toggle="tooltip"
                        title="The maximum number of tokens to consider when sampling"
                        className="mr-2"
                      />
                      <p className="m-0">Top K</p>
                    </div>

                    <div className="d-flex align-items-center w-100 pl-3">
                      <input
                        type="range"
                        min="1"
                        max="40"
                        value={values.topK}
                        onChange={handleChange}
                        name="topK"
                        className={` ${styles["slider"]} w-100`}
                        data-orientation="vertical"
                      />
                      <p className="mb-0 ml-2">{values.topK}</p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div
                      className={`d-flex align-items-center ${styles["config-name"]}`}
                    >
                      <img
                        src="/images/question.svg"
                        data-toggle="tooltip"
                        title="The maximum cumulative probability of tokens to consider when sampling"
                        className="mr-2"
                      />
                      <p className="m-0">Top P</p>
                    </div>

                    <div className="d-flex align-items-center w-100 pl-3">
                      <input
                        type="range"
                        min="0.0"
                        max="1.0"
                        step="0.05"
                        value={values.topP}
                        onChange={handleChange}
                        name="topP"
                        className={` ${styles["slider"]} w-100`}
                        data-orientation="vertical"
                      />
                      <p className="mb-0 ml-2">{values.topP}</p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div
                      className={`d-flex align-items-center ${styles["config-name"]}`}
                    >
                      <img
                        src="/images/question.svg"
                        data-toggle="tooltip"
                        title="Frequency penalty applied to the next token's logprobs, multiplied by the number of times each token has been seen in the respponse so far.
                            A positive penalty will discourage the use of tokens that have already been used, proportional to the number of times the token has been used: The more a token is used, the more dificult it is for the model to use that token again increasing the vocabulary of responses"
                        className="mr-2"
                      />
                      <p className="m-0">Frequency penalty</p>
                    </div>

                    <div className="d-flex align-items-center w-100 pl-3">
                      <input
                        type="range"
                        min="-2"
                        max="2"
                        step="0.1"
                        value={values.frequencyPenalty}
                        onChange={handleChange}
                        name="frequencyPenalty"
                        className={` ${styles["slider"]} w-100`}
                        data-orientation="vertical"
                      />
                      <p className="mb-0 ml-2">{values.frequencyPenalty}</p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div
                      className={`d-flex align-items-center ${styles["config-name"]}`}
                    >
                      <img
                        src="/images/question.svg"
                        data-toggle="tooltip"
                        title="A positive penalty will discourage the use of tokens that have already been used in the response, increasing the vocabulary.
                            A negative penalty will encourage the use of tokens that have already been used in the response, decreasing the vocabulary"
                        className="mr-2"
                      />
                      <p className="m-0">Presence penalty</p>
                    </div>

                    <div className="d-flex align-items-center w-100 pl-3">
                      <input
                        type="range"
                        min="-2"
                        max="2"
                        step="0.1"
                        value={values.presencePenalty}
                        name="presencePenalty"
                        onChange={handleChange}
                        className={` ${styles["slider"]} w-100`}
                        data-orientation="vertical"
                      />
                      <p className="mb-0 ml-2">{values.presencePenalty}</p>
                    </div>
                  </div>
                </Collapse>
                <div className="w-100 d-flex align-items-center justify-content-center mt-5">
                  <div onClick={toggle} className="cursor-pointer d-flex align-items-center">
                    <p className="mb-0 mr-2">Expand</p>
                    <IoIosArrowDown style={{
                      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}/>
                  </div>
                </div>
              </div>
              <div className="my-3 w-75 border rounded p-3">
                <h5>Select Instruction</h5>
                <div className="d-flex align-items-center justify-content-between">
                  <CommonSelector
                    className="w-25"
                    defaultValue={values.instruction}
                    options={instructionOptions}
                    onChange={(e: any) => {
                      setFieldValue("instruction", e.value ?? 0);
                    }}
                  />
                  <Input
                    type="text"
                    name="question"
                    value={values.question}
                    className="w-50"
                    onChange={handleChange}
                    placeholder="Question"
                  />
                </div>

                <Table responsive bordered striped hover size="sm" className={`mb-3 mt-4`}>
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      <th>Grade</th>
                      <th>Question</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sampleDataSession.map((answer, index) => (
                      <tr key={index}>
                        <td>{answer.studentName}</td>
                        <td>{answer.grade}</td>
                        <td>{answer.question}</td>
                        <td>{answer.answer}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className="border rounded p-3 mt-3 mb-5">
                <h5>Message List</h5>

                {values.messages.map((r: IMessage, index: number) => (
                  <div className={`border-bottom py-2 d-flex row`}>
                    <div className="col-sm-3 col-lg-2">
                      <p
                        onClick={() => {
                          setFieldValue(
                            `messages[${index}].role`,
                            toggleRole(r.role)
                          );
                        }}
                        className={`cursor-pointer py-2 px-3 rounded ${styles["message-role"]}`}
                      >
                        {MessageRole[r.role]}
                      </p>
                    </div>
                    <div className="w-100 col-sm-9 col-lg-10 d-flex">
                      <textarea
                        name={`messages[${index}].message`}
                        value={r.message}
                        ref={(el) => {
                          if (el) {
                            textareasRef.current[index] = el;
                          }
                        }}
                        className={`w-100 rounded ${styles["message-input"]} ${
                          styles[
                            !r.message?.trim() ? "message-input-active" : ""
                          ]
                        }`}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          setFieldValue(
                            `messages[${index}].message`,
                            e.target.value
                          );
                        }}
                        onInput={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                          const target = e.target;
                          // target.style.height = "auto";
                          target.style.height = `${target.scrollHeight}px`;
                        }}
                      />

                      <img
                        onClick={() =>
                          handleDeleteMessage(index, setFieldValue, values)
                        }
                        src="/images/minus-circle.svg"
                        className={`ml-3 cursor-pointer ${styles["delete-icon"]}`}
                      />
                    </div>
                  </div>
                ))}

                <div
                  onClick={handleAddMessage}
                  className={`d-flex align-items-center rounded px-3 py-2 mt-2 ${styles["add-message"]}`}
                >
                  <img src="/images/plus-circle.svg" className="mr-4" />
                  <p className="mb-0">Add message</p>
                </div>
              </div>
              <DetailActionButtons
                confirmText={!id ? "Create" : "Update"}
                onConfirm={handleSubmit}
                onCancel={backToPromptList}
              />
            </Fragment>
          );
        }}
      </Formik>
    </div>
  );
};

export default PromptDetail;
