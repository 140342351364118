import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoading, setAlert } from "../../../redux/commons/action";

import { IHistoryPrompt, IPrompt } from "../ultils/type";
import { getHistoryById } from "../../../services/promptService";
import { useParams } from "react-router-dom";

const useHistoryPrompt = () => {
  //   const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [historyDetail, setHistoryDetail] = useState<IHistoryPrompt>();

  const getHistoryPromptById = async (id: string) => {
    dispatch(setLoading(true));
    try {
      const res = await getHistoryById(parseInt(id));
      if (res.data) {
        setHistoryDetail(res.data);
      }
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (id) {
      getHistoryPromptById(id);
    }
  }, [id]);

  return { historyDetail };
};

export default useHistoryPrompt;
