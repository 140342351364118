import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";
import SearchBoxContainer from "../../../components/Inputs/SearchBoxContainer";
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import usePromptList from "../hooks/usePromptList";
import { AiFillEdit } from "react-icons/ai";

const Prompts = () => {
  const {
    goToDetail,
    filter,
    changeFilter,
    prompts,
    PROMPT_DETAIL_URL,
    removeData,
    totalItems,
  } = usePromptList();
  return (
    <div className="animated fadeIn">
      <h4>Prompt list</h4>
      <Row className="mb-2">
        <Col md={8}>
          <SearchBoxContainer
            text="Add prompt"
            onClick={() => goToDetail()}
            initValue={filter.search}
            onSearch={(string) => changeFilter({ search: string })}
          />
        </Col>
      </Row>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th></th>
            <th>Name Prompt</th>
            <th>Model</th>
            <th>Temperature</th>
            <th>Max Output Tokens</th>
            <th>Top K</th>
            <th>Top P</th>
            <th>Frequency Penalty</th>
            <th>Presence Penalty</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {prompts?.map((prompt, index) => (
            <tr key={prompt?.id}>
              <td>{index + 1}</td>
              <td>
                <Link to={`${PROMPT_DETAIL_URL}/${prompt.id}`}>
                  {prompt.name}
                </Link>
              </td>
              <td>{prompt.aiModel}</td>
              <td>{prompt.temperature}</td>
              <td>{prompt.maxOutputTokens}</td>
              <td>{prompt.topK}</td>
              <td>{prompt.topP}</td>
              <td>{prompt.frequencyPenalty}</td>
              <td>{prompt.presencePenalty}</td>
              <td className="text-center">
                <Link to={`${PROMPT_DETAIL_URL}/${prompt.id}`}>
                  <AiFillEdit className="mr-2 cursor-pointer hover-opacity" />
                </Link>

                <DeleteButtonIcon
                  onClick={() => removeData(prompt.id as number)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-right">
        <CustomPagination
          page={filter.page}
          limit={filter.limit}
          totalItems={totalItems}
          changePage={(page: number) => changeFilter({ page })}
          changeLimit={(limit: number) => changeFilter({ limit })}
        />
      </div>
    </div>
  );
};

export default Prompts;
