import { api } from "./api"
import { Filter } from "../types/Filter"
import { IHistoryPrompt, IPrompt, IPromptParams } from "../containers/AiPrompts/ultils/type"

const PROMPT_URL = "/api/Prompt"
const SCHOOL_URL = "/api/school"

export const getAllSchool = (filter: Filter) => api.get(SCHOOL_URL, { params: filter })

export const get = (filter?: Filter) =>
  api.get(PROMPT_URL, { params: filter })

export const getById = (id: number) => api.get(`${PROMPT_URL}/${id}`)

export const create = (data: IPrompt) => api.post(PROMPT_URL, data)

export const update = (data: IPrompt) =>
  api.put(`${PROMPT_URL}/${data.id}`, data)

export const remove = (id: number) => api.delete(`${PROMPT_URL}/${id}`)

export const getSessionAnswer = (params: IPromptParams) =>
  api.get(`${PROMPT_URL}/session-data`, { params })

export const createHistory = (data: IHistoryPrompt) => api.post(`${PROMPT_URL}/create-history`, data)

export const getHistory = (filter?: Filter) =>
  api.get(`${PROMPT_URL}/history`, { params: filter })

export const removeHistory = (id: number) => api.delete(`${PROMPT_URL}/history/${id}`)

export const getHistoryById = (id: number) => api.get(`${PROMPT_URL}/history/${id}`)